import React, { useEffect, useState } from 'react';
import { NB, Button } from '@cerebral-inc/ui';
import {
  fromScreenHintParam,
  getAuthorizationCode,
  toMobileLoggedInUrlWithRefreshToken,
  toMobileLoginUrl
} from 'types/universal-login.utils';
import tracking from 'utils/tracking';
import { Spinner } from '@cerebral-inc/design-system';
import { bufferToBase64UrlEncoded, createRandomString, encode, getAuthorizationUrl, sha256 } from './mobile-utils';
import purple_logo from '../images/purple_logo.svg';

export const MobileLogin = (): JSX.Element => {
  const { Flex, Text, Box } = NB;
  const [errorMessage, setErrorMessage] = useState('');

  const navigateToLogin = async (screenHint?: string) => {
    const code_verifier = createRandomString();
    const code_challengeBuffer = await sha256(code_verifier);
    const codeChallenge = bufferToBase64UrlEncoded(code_challengeBuffer);
    const state = encode(createRandomString());
    const nonce = encode(createRandomString());
    const screen_hint = screenHint;
    const domain = process.env.REACT_APP_AUTH0_DOMAIN ?? '';
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID ?? '';
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE ?? '';
    sessionStorage.setItem('code_verifier', code_verifier);
    const url = getAuthorizationUrl(
      domain,
      codeChallenge,
      clientId,
      toMobileLoginUrl(),
      audience,
      state,
      nonce,
      screen_hint
    );

    window.onbeforeunload = null;
    window.location.href = url;
  };

  const emitTokens = async (authorizationCode: string) => {
    try {
      const codeVerifier = sessionStorage.getItem('code_verifier');
      const url = `${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`;
      const fetchOptions = {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          grant_type: 'authorization_code',
          client_id: `${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
          code_verifier: `${codeVerifier}`,
          code: `${authorizationCode}`,
          redirect_uri: `${toMobileLoginUrl()}`
        })
      };
      const response = await fetch(url, fetchOptions);
      const token = await response.json();
      if (!token.access_token || !token.refresh_token) {
        const message = 'Not found access token or refresh token';
        const error = new Error(message);
        tracking.errorV2(message, error);
        throw error;
      }

      const redirectUrl = toMobileLoggedInUrlWithRefreshToken(token.access_token, token.refresh_token);
      const trustedRedirectUrlRegex = /^cerebralmobile:\/\/auth\/token\//;
      if (trustedRedirectUrlRegex.test(redirectUrl)) {
        window.location.replace(redirectUrl);
      }
    } catch (error) {
      setErrorMessage('There is an error while logging in. Please try again.');
    }
  };

  useEffect(() => {
    const authorizationCode = getAuthorizationCode();
    if (authorizationCode) {
      emitTokens(authorizationCode);
      return;
    }

    navigateToLogin(fromScreenHintParam());
  }, []);

  if (errorMessage)
    return (
      <>
        <Box alignSelf="center" margin="50px">
          <img className="cerebral-logo" src={purple_logo} alt="Cerebral logo" />
        </Box>
        <Flex direction="row" marginTop="40px" marginBottom="60px" justifyContent="center" alignItems="center">
          <Text fontSize="xl" color="red.600">
            {errorMessage}
          </Text>
        </Flex>
        <Box alignItems="center" flexDirection="column">
          <Button variant="primary" size="lg" onPress={() => navigateToLogin()} w="320px">
            Login with another account
          </Button>
          <input
            className="btn-link"
            onClick={() => navigateToLogin('signup')}
            type="button"
            style={{ color: '#385ade', textDecoration: 'underline', fontSize: '14px' }}
            value="Create a new account."
          />
        </Box>
      </>
    );

  return <Spinner variant="screen" hideScreenOverlay />;
};
