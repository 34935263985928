import { isFromMobile, toMobileLoggedOutUrl, toWebCallbackUrl } from 'types/universal-login.utils';
import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { sign_out } from 'actions/user_auth_action';
import { RNWindow } from 'utils/react-native-webview-window';
import { useFeatureFlags } from 'features';
import { Spinner } from '@cerebral-inc/design-system';

export const Logout = (): JSX.Element => {
  const dispatchRedux = useDispatch();
  const history = useHistory();
  const { logout } = useAuth0();
  const { data: flags } = useFeatureFlags();

  useEffect(() => {
    sessionStorage.removeItem('rteTriggerPoint');

    if (flags?.auth_userlogin_auth0 === null || flags?.auth_userlogin_auth0 === undefined) return;

    (async () => {
      await dispatchRedux(sign_out());
      if (flags?.auth_userlogin_auth0) {
        window.onbeforeunload = null;
        if (isFromMobile()) {
          await logout({ logoutParams: { returnTo: toMobileLoggedOutUrl() } });
        } else {
          await logout({ logoutParams: { returnTo: toWebCallbackUrl() } });
        }

        return;
      }

      if (RNWindow.ReactNativeWebView !== undefined) {
        RNWindow.ReactNativeWebView.postMessage('close');
      }

      history.push('/member_sign_in');
    })();
  }, [flags?.auth_userlogin_auth0]);
  return <Spinner variant="screen" hideScreenOverlay />;
};

export default Logout;
