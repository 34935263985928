import React from 'react';
import { Body, Center, Flex, Heading, TakeAssessment, Text } from '@cerebral-inc/design-system';
import { DimensionValue } from '@cerebral-inc/design-system/types/types/commonProps';
import { RebrandOnboardingButton } from 'components/Onboarding/shared/OnboardingButton/RebrandOnboardingButton';
import { copy } from './constants';

export interface GettingStartedProps {
  currentStep: number;
  handleContinue: () => void;
}

export const GettingStarted = ({ currentStep, handleContinue }: GettingStartedProps) => {
  return (
    <Flex
      alignItems="center"
      minHeight={'calc(100vh - 67px)' as DimensionValue}
      paddingTop={40}
      backgroundColor="warmGray100"
      width="100%"
    >
      <Flex
        width={{ base: '100%', sm: 320 }}
        paddingHorizontal={{ base: 24, sm: 0 }}
        minHeight={{ base: 'calc(100vh - 196px)' as DimensionValue, md: 'auto' }}
        alignItems="center"
      >
        <TakeAssessment size={64} />
        <Heading size="h3" color="warmGray900" marginTop={16}>
          Next steps
        </Heading>
        <Body size="small" color="#353A41" textAlign="center" marginTop={8}>
          This should only take about 1 minute
        </Body>
        <Flex marginTop={32} gap={12} width="100%">
          {copy.steps.map((step, index) => (
            <Flex
              gap={12}
              flexDirection="row"
              paddingHorizontal={12}
              paddingTop={16}
              paddingBottom={16}
              background={currentStep === index + 1 ? '#FFF' : 'warmGray100'}
              borderRadius={currentStep === index + 1 ? 8 : 0}
              borderWidth={currentStep === index + 1 ? 1 : 0}
              borderColor="#8B77C4"
              alignItems="center"
            >
              <Center
                background={currentStep === index + 1 ? '#C5C9FF' : '#EEEFFF'}
                width={24}
                height={24}
                borderRadius={8}
              >
                <Text color="warmGray800" fontFamily="MozaicHum">{`${index + 1}`}</Text>
              </Center>
              <Text color="warmGray900" fontFamily="MozaicHum" lineHeight={20} style={{ fontWeight: 420 }}>
                {step}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Flex>

      <RebrandOnboardingButton text="Continue" onSubmit={handleContinue} isStickyButton marginTop={40} />
    </Flex>
  );
};
