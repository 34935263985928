import { parse, ParsedQuery } from 'query-string';
import { noop } from './default-values';
import {
  ANXIETY_ASSESSMENT_RESULT_CONTINUE_BUTTON,
  DEPRESSION_ASSESSMENT_RESULT_CONTINUE_BUTTON,
  MED_99_EVENTS,
  MED_UPSELL_EVENTS,
  MMSP_EVENT
} from '../types/segment-events';
import type { OfferingKey } from './plans';
import { RNWindow } from './react-native-webview-window';

export interface TrackingParams {
  [key: string]:
    | string
    | number
    | ObjectProduct[]
    | null
    | undefined
    | Date
    | boolean
    | string[]
    | Record<string, unknown>;
}
interface ObjectProduct {
  [key: string]: string | number | null;
}

interface ObjectString {
  [key: string]: string;
}

export interface SegmentContext {
  context?: {
    device: {
      type: string | string[];
      advertisingId?: string | string[];
    };
  };
  referrer?: {
    type: string;
    id: string;
  };
  integrations?:
    | {
        AppsFlyer?: {
          appsFlyerId: string | string[];
        };
      }
    | Record<string, boolean | Record<string, boolean>>;
}

const SUBSCRIPTION_CANCELLATION_SUCCEED = 'Subscription cancellation succeed';
const SUBSCRIPTION_CANCELLATION_FAILED = 'Subscription cancellation failed';
const CONTACT_SUPPORT_CHANGE_PLAN = 'Contact Support to change plan';
const CONTACT_SUPPORT_FOR_INSURANCE = 'Contact Support for insurance';
const SUBSCRIPTION_REACTIVATION_SUCCEED = 'Subscription reactivation succeed';
const SUBSCRIPTION_REACTIVATION_FAILED = 'Subscription reactivation failed';
const SUBSCRIPTION_REACTIVATION_CHECKOUT_SUCCEED = 'Subscription checkout reactivation succeed';
const SUBSCRIPTION_REACTIVATION_CHECKOUT_FAILED = 'Subscription checkout reactivation failed';
export const NEW_PHONE_NUMBER_SCREEN = 'New phone number screen';
export const VIEW_HOW_IT_WORKS_SCREEN = 'View - How It Works Page';
export const VIEW_PREFERRED_NAME_SCREEN = 'View - Preferred First Name Page';
export const CLICK_CONTINUE_ON_PREFERRED_NAME_SCREEN = 'Click - Continue on Preferred First Name Page';
export const VIEW_GREETING_SCREEN = 'View - Greetings Page';
export const VIEW_ZIPCODE_SCREEN = 'View - Zip Code Page';
export const CLICK_CONTINUE_ON_ZIPCODE_SCREEN = 'Click - Continue on Zip Code Page';
export const ACCEPT_HIPAA_MARKETING_AUTHORIZATION = 'HIPAA Marketing Authorization Accepted';
export const FUNNEL_STARTED = 'Funnel Started';
export const DECLINE_HIPAA_MARKETING_AUTHORIZATION = 'HIPAA Marketing Authorization Declined';
export const HIPAA_MARKETING_CHECKBOX_CLICKED = 'HIPAA Marketing Authorization Checkbox Clicked';
export const CLICK_RAF_PATIENT_DASHBOARD_PLACEMENT = 'Click - RAF Patient Dashboard Placement';
export const CLICK_RAF_PATIENT_DASHBOARD_BANNER_ACTIVE = 'Click - RAF Patient Dashboard Banner Active';
export const CLICK_RAF_PATIENT_DASHBOARD_MENU = 'Click - RAF Patient Dashboard Menu';
export const VIEW_RAF_PATIENT_DASHBOARD_ADVOCATE = 'View - RAF Patient Dashboard Advocate';
export const VIEW_NEW_CHECKOUT_VARIANT = 'View new checkout variant';
export const CHECKOUT_PAYMENT_FAQ_CLICKED = 'Checkout payment FAQ clicked';
export const SELECTED_BILLING_VARIANT = 'Selected billing variant';
export const PODSCRIBE_ORDER_COMPLETED = 'Podscribe Order Completed';
export const PODSCRIBE_ACCOUNT_CREATE = 'Podscribe Account Create';
export const FUNNEL_COMPLETE_EMAIL_INCLUDED = 'Funnel Complete Email Included';
export const SERVICE_SELECTED = 'User selected service';
export const USER_CLICKED_CHOOSE_DIFFERENT_SERVICES = 'User clicked choose different services';
export const PLAN_CHANGED = 'Change plan';
export const TRANSACTIONAL_SMS_CHECKBOX_CLICKED = 'Transactional SMS Checkbox Clicked';
export const TCPA_SMS_CHECKBOX_CLICKED = 'TCPA SMS Checkbox Clicked';
export const SUBMIT_ANSWERS_PRE_SIGNUP_FEELING_QUESTION = 'Submit - Pre-signup: How are you feeling?';
export const SUBMIT_COUPLES_THERAPY_STATE_QUESTION = 'Submit - Couples Therapy State Question';
export const SUBMIT_COUPLES_THERAPY_EXCLUSIONS_QUESTION = 'Submit - Couples Therapy Exclusions Question';
export const ACCOUNT_CREATE = 'Account Create';
export const ORDER_COMPLETED = 'Order Completed';
export const CHOOSE_OPTION = 'Choose Option';
export const FUNNEL_COMPLETE_ADD = 'Funnel Complete Add';
export const INITIATE_FUNNEL_COMPLETE = 'Initiate Funnel Complete';
export const CANCELLATION_HIPAA_MARKETING_AUTHORIZATION = 'Cancellation HIPAA Marketing Authorization';
export const NEW_PROGRESS_PAGE = 'NewProgressPage';

// Screener
export const SCREENERS_ACC_CREATION_PLACEHOLDER = 'Screener Account Creation';

const segmentEvents = [
  'appointment_booked',
  'Referral success',
  'Referral fail',
  'initial_counselor_appointment_booked',
  'initial_provider_appointment_booked',
  'initial_therapist_appointment_booked',
  'Booked prescriber in the onboarding flow (waitlist)',
  'View - Cancel and refund page for beneficiary',
  'View - Clinician is not available pop-up',
  'Click - Cancel button from Clinician is not available pop-up',
  'Click - View other clinicians button from Clinician is not available pop-up',
  'Clicked on schedule later (waitlist)',
  'Viewed the waitlist',
  'View INI Plan update page',
  'Click Verify Eligibility button',
  'RTE check PASS',
  'RTE check FAIL',
  'Continue without insurance',
  'Click Continue button - Insurance card page',
  'Click Skip for now button - Insurance card page',
  '[Insurance payer v2] - Choose your plan',
  'last_page_of_screen',
  'Click - Confirm and continue in Beneficiary popup',
  'Click - I am a beneficiary in Beneficiary popup',
  'View - Subscription cancellation for beneficiary popup',
  'Click - Cancel and refund in Subscription cancellation for beneficiary popup',
  'View - Beneficiary popup',
  'Click - Go back in Subscription cancellation for beneficiary popup',
  'Assessment Start',
  'select_service_line',
  'choose_in_insurance',
  '[Persona] Click Done - You are done modal',
  '[Persona] View Getting started page',
  'first_page_of_screener',
  'View - /id-completed',
  '[Cerebral.com] View - /id-declined',
  '[Cerebral.com] View - /id-needs-review',
  'meds-counseling-99-to-meds-therapy-upsell',
  'Subscribed the waitlist',
  'Click - Submit button in Willingness to Pay screen',
  'View - V1 text with discount pop-up',
  'View - V2 image with discount pop-up',
  'View - V3 text with benefits pop-up',
  'View - V4 image with benefits pop-up',
  'Subscription Signup',
  'ADHD Sub Purchase',
  'Med mgmt Sub Purchase',
  'Med-mgmt-99-Sub-Purchase',
  'Therapy Sub Purchase',
  'Therapy + Meds Sub Purchase',
  'Couples Therapy Sub Purchase',
  'Start Strong + Therapy Sub Purchase',
  'Start Strong + Therapy + Meds Sub Purchase',
  'Start Strong + Med-mgmt-99-Sub-Purchase',
  'Counseling Subscription',
  'Nutrition + Therapy Subscription',
  'Nutrition + MM + Therapy Subscription',
  'MM + CC + Nutrition Subscription',
  'Coaching Sub Purchase',
  'Medication Coaching Sub Purchase',
  'MAT Counseling Sub Purchase',
  'Bipolar Sub Purchase',
  'First Adhoc Therapy Purchased',
  'Second Adhoc Therapy Purchased',
  'FSA_PAYMENT_CHECKOUT_COMPLETED',
  'Upsell from Meds-counseling to Medication coaching',
  'meds-counseling-to-meds-therapy-upsell',
  'therapy-to-meds-therapy-upsell',
  'upgrade_first_adhoc_therapy_to_therapy',
  'upgrade_first_adhoc_therapy_to_meds_therapy',
  'upgrade_second_adhoc_therapy_to_therapy',
  'upgrade_second_adhoc_therapy_to_meds_therapy',
  'upgrade_meds_cc_1st_trial_therapy_to_meds_therapy',
  'upgrade_meds_cc_2nd_trial_therapy_to_meds_therapy',
  'Telehealth consent signed',
  'Checkout Initiate',
  'Assessment Complete',
  'Viewed SelectPlan',
  'change_plan_meds_ini_to_meds',
  'change_plan_meds_ini_to_meds_therapy',
  'change_plan_meds_ini_to_therapy',
  'change_plan_meds_therapy_ini_to_meds_therapy',
  'change_plan_meds_therapy_ini_to_meds',
  'change_plan_meds_therapy_ini_to_therapy',
  'change_plan_therapy_ini_to_meds_therapy',
  'change_plan_therapy_ini_to_therapy',
  'change_plan_therapy_ini_to_meds',
  'change_plan_meds_counseling_to_meds_ini',
  'change_plan_meds_counseling_to_meds_therapy_ini',
  'change_plan_med_therapy_to_meds_therapy',
  'change_plan_therapy_to_meds_therapy_ini',
  'change_plan_therapy_to_therapy_ini',
  'Page View',
  'Subscription Reactivate',
  'ILV Modal Viewed',
  MMSP_EVENT.STRONG_START_SELECTED,
  SUBSCRIPTION_CANCELLATION_SUCCEED,
  SUBSCRIPTION_CANCELLATION_FAILED,
  CONTACT_SUPPORT_CHANGE_PLAN,
  SUBSCRIPTION_REACTIVATION_SUCCEED,
  SUBSCRIPTION_REACTIVATION_FAILED,
  CONTACT_SUPPORT_FOR_INSURANCE,
  SUBSCRIPTION_REACTIVATION_CHECKOUT_SUCCEED,
  SUBSCRIPTION_REACTIVATION_CHECKOUT_FAILED,
  NEW_PHONE_NUMBER_SCREEN,
  VIEW_HOW_IT_WORKS_SCREEN,
  VIEW_PREFERRED_NAME_SCREEN,
  CLICK_CONTINUE_ON_PREFERRED_NAME_SCREEN,
  VIEW_GREETING_SCREEN,
  VIEW_ZIPCODE_SCREEN,
  CLICK_CONTINUE_ON_ZIPCODE_SCREEN,
  ACCEPT_HIPAA_MARKETING_AUTHORIZATION,
  DECLINE_HIPAA_MARKETING_AUTHORIZATION,
  HIPAA_MARKETING_CHECKBOX_CLICKED,
  VIEW_NEW_CHECKOUT_VARIANT,
  CHECKOUT_PAYMENT_FAQ_CLICKED,
  SELECTED_BILLING_VARIANT,
  PODSCRIBE_ORDER_COMPLETED,
  PODSCRIBE_ACCOUNT_CREATE,
  FUNNEL_COMPLETE_EMAIL_INCLUDED,
  SERVICE_SELECTED,
  USER_CLICKED_CHOOSE_DIFFERENT_SERVICES,
  PLAN_CHANGED,
  TRANSACTIONAL_SMS_CHECKBOX_CLICKED,
  TCPA_SMS_CHECKBOX_CLICKED,
  SUBMIT_COUPLES_THERAPY_STATE_QUESTION,
  SUBMIT_COUPLES_THERAPY_EXCLUSIONS_QUESTION,
  SUBMIT_ANSWERS_PRE_SIGNUP_FEELING_QUESTION,
  ACCOUNT_CREATE,
  ORDER_COMPLETED,
  CHOOSE_OPTION,
  FUNNEL_COMPLETE_ADD,
  INITIATE_FUNNEL_COMPLETE,
  ANXIETY_ASSESSMENT_RESULT_CONTINUE_BUTTON,
  DEPRESSION_ASSESSMENT_RESULT_CONTINUE_BUTTON,
  MED_UPSELL_EVENTS.CLICK_ADD_THERAPY,
  MED_UPSELL_EVENTS.CLICK_CONTINUE_MEDICATION,
  MED_UPSELL_EVENTS.OPT_OUT_OF_UPSELL,
  CANCELLATION_HIPAA_MARKETING_AUTHORIZATION,
  `${SCREENERS_ACC_CREATION_PLACEHOLDER} adhd`,
  `${SCREENERS_ACC_CREATION_PLACEHOLDER} anxiety`,
  `${SCREENERS_ACC_CREATION_PLACEHOLDER} depression`,
  `${SCREENERS_ACC_CREATION_PLACEHOLDER} bipolar`,
  NEW_PROGRESS_PAGE
];

export const formatPrice = (price: string) => {
  const parseNum = parseFloat(price);
  return ((Number.isNaN(parseNum) && 85) || parseNum).toFixed(2);
};

const track = (event: string, opts?: Partial<TrackingParams>, segmentContext?: SegmentContext | null): void => {
  // Prevents tracking of events not included in the segmentEvents array.
  if (!segmentEvents.includes(event)) return;
  if (!window.analytics) return;

  window.analytics?.track(event, opts, segmentContext);

  try {
    // call back to react-native for appsflyer analytic destination
    const eventObject = { eventName: event, eventParams: opts };
    const eventData = JSON.stringify(eventObject);
    RNWindow.ReactNativeWebView?.postMessage(eventData);
  } catch (_) {
    // empty
  }
};

export const convertCampaignId = (campaignId: string): string | number => {
  return /^[1-9].[0-9]*$/.test(campaignId) ? Number(campaignId) : campaignId;
};

export const mapSubscriptionTypeByOfferingKey: ObjectString = {
  'medication-adhd-115-management': 'ADHD Sub Purchase',
  'medication-adhd-135-management': 'ADHD Sub Purchase',
  medication: 'Med mgmt Sub Purchase',
  couples_therapy: 'Couples Therapy Sub Purchase',
  '99_med_management': 'Med mgmt Sub Purchase',
  '109_med_management': 'Med mgmt Sub Purchase',
  '119_med_management': 'Med mgmt Sub Purchase',
  'medication-management-85': 'Med mgmt Sub Purchase',
  medication_99: MED_99_EVENTS.PURCHASED,
  therapy: 'Therapy Sub Purchase',
  '275_therapy': 'Therapy Sub Purchase',
  '290_therapy': 'Therapy Sub Purchase',
  'medication-therapy': 'Therapy + Meds Sub Purchase',
  '345_meds_therapy': 'Therapy + Meds Sub Purchase',
  '365_meds_therapy': 'Therapy + Meds Sub Purchase',
  counseling: 'Counseling Subscription',
  'nutrition-management-therapy': 'Nutrition + Therapy Subscription',
  'nutrition-medication-management-therapy': 'Nutrition + MM + Therapy Subscription',
  coaching: 'Coaching Sub Purchase',
  'coaching-abtest': 'Coaching Sub Purchase',
  'medication-coaching': 'Medication Coaching Sub Purchase',
  'mat-counseling': 'MAT Counseling Sub Purchase',
  bipolar_115_medication_management: 'Bipolar Sub Purchase',
  bipolar_135_medication_management: 'Bipolar Sub Purchase',
  'first-ad-hoc-therapy': 'First Adhoc Therapy Purchased',
  'second-ad-hoc-therapy': 'Second Adhoc Therapy Purchased',
  'medication-first-ad-hoc-therapy': 'First Adhoc Therapy Purchased',
  'medication-second-ad-hoc-therapy': 'Second Adhoc Therapy Purchased'
};

type SubscriptionPathType = 'new' | 'reactivation' | 'plan-change';
export interface ITrackSubscriptionParams {
  offeringKey: OfferingKey;
  price: string;
  campaignId: string;
  insurance: { status: 'in_network' | string } | boolean;
  orderId: string;
  eventID: string;
  context?: SegmentContext | null;
  planId?: number;
  patientId?: number;
  fsa?: boolean;
  term?: string;
  termMonths?: string;
  isStrongStart?: boolean;
  planNameAlias?: string;
  ltv?: number;
  subscriptionFlow: SubscriptionPathType;
}

const trackUpsell = (oldPlan: string, price: string, newPlan = '', context?: SegmentContext | null): void => {
  const params = {
    revenue: formatPrice(price),
    value: formatPrice(price),
    currency: 'USD'
  };

  interface PlanGetter {
    [key: string]: () => string;
  }

  const plans = {
    'medication-+-care-counseling': () => {
      if (newPlan === 'medication-coaching') {
        return 'Upsell from Meds-counseling to Medication coaching';
      }

      return 'meds-counseling-to-meds-therapy-upsell';
    },
    therapy: () => {
      return 'therapy-to-meds-therapy-upsell';
    },
    'first-ad-hoc-therapy': () => {
      if (newPlan === 'therapy') {
        return 'upgrade_first_adhoc_therapy_to_therapy';
      }
      if (newPlan === 'medication-therapy') {
        return 'upgrade_first_adhoc_therapy_to_meds_therapy';
      }
      return null;
    },
    'second-ad-hoc-therapy': () => {
      if (newPlan === 'therapy') {
        return 'upgrade_second_adhoc_therapy_to_therapy';
      }
      if (newPlan === 'medication-therapy') {
        return 'upgrade_second_adhoc_therapy_to_meds_therapy';
      }
      return null;
    },
    'medication-first-ad-hoc-therapy': () => {
      if (newPlan === 'medication-therapy') {
        return 'upgrade_meds_cc_1st_trial_therapy_to_meds_therapy';
      }
      return null;
    },
    'medication-second-ad-hoc-therapy': () => {
      if (newPlan === 'medication-therapy') {
        return 'upgrade_meds_cc_2nd_trial_therapy_to_meds_therapy';
      }
      return null;
    }
  } as PlanGetter;

  const trackStringGetter = plans[oldPlan] || noop;
  const trackingUrl = trackStringGetter();
  if (trackingUrl) {
    track(trackingUrl, params, context);
  }
};

const telehealthConsent = (): void => {
  track('Telehealth consent signed');
};

const eventBasedOnPath = (path: string, opts?: Partial<TrackingParams>, context?: SegmentContext | null): void => {
  switch (path) {
    case '/patient/question_bank/checkout/payment':
    case '/patient/question_bank/online_checkout/check_out':
    case '/patient/question_bank/unified_checkout/check_out':
      track('Checkout Initiate', opts, context);
      break;
    case '/patient/question_bank/assessment_end/results':
      track('Assessment Complete', opts, context);
      break;
    case 'patient/question_bank/plan_selection/select_plan':
    case 'patient/question_bank/online_checkout/view_plans':
      track('Viewed SelectPlan', opts, context);
      break;
    default:
      break;
  }
};

const changePlanFlow = (prevStatus: string, prevOffering: string, newStatus: string, newOffering: string): void => {
  if (prevStatus === 'insurance' && newStatus === 'cash') {
    switch (prevOffering) {
      case 'medication':
      case 'medication_99':
        if (newOffering === 'medication' || newOffering === 'medication_99') {
          track('change_plan_meds_ini_to_meds');
        } else if (newOffering === 'medication-therapy') {
          track('change_plan_meds_ini_to_meds_therapy');
        } else if (newOffering === 'therapy') {
          track('change_plan_meds_ini_to_therapy');
        }
        break;
      case 'medication-therapy':
        if (newOffering === 'medication-therapy') {
          track('change_plan_meds_therapy_ini_to_meds_therapy');
        } else if (newOffering === 'medication' || newOffering === 'medication_99') {
          track('change_plan_meds_therapy_ini_to_meds');
        } else if (newOffering === 'therapy') {
          track('change_plan_meds_therapy_ini_to_therapy');
        }
        break;
      case 'therapy':
        if (newOffering === 'medication-therapy') {
          track('change_plan_therapy_ini_to_meds_therapy');
        } else if (newOffering === 'therapy') {
          track('change_plan_therapy_ini_to_therapy');
        } else if (newOffering === 'medication' || newOffering === 'medication_99') {
          track('change_plan_therapy_ini_to_meds');
        }
        break;
      default:
        break;
    }
  } else if (prevStatus === 'cash' && newStatus === 'insurance') {
    switch (prevOffering) {
      case 'medication':
      case 'medication_99':
        if (newOffering === 'medication_99' || newOffering === 'medication') {
          track('change_plan_meds_counseling_to_meds_ini');
        } else if (newOffering === 'medication-therapy') {
          track('change_plan_meds_counseling_to_meds_therapy_ini');
        }
        break;
      case 'medication-therapy':
        if (newOffering === 'medication-therapy') {
          track('change_plan_med_therapy_to_meds_therapy');
        }
        break;
      case 'therapy':
        if (newOffering === 'medication-therapy') {
          track('change_plan_therapy_to_meds_therapy_ini');
        } else if (newOffering === 'therapy') {
          track('change_plan_therapy_to_therapy_ini');
        }
        break;
      default:
        break;
    }
  }
};

const identify = (id: number | null, email: string | null): void => {
  if (!window.analytics || !id || !email) return;

  if (window.sessionStorage.getItem('fromMobileUser')) {
    window.analytics.identify(
      id,
      {
        email
      },
      {
        context: {
          campaign: {
            source: 'mobile_app'
          }
        }
      }
    );
  } else {
    window.analytics.identify(id, {
      email
    });
  }
};

const page = (path: string, opts?: Partial<TrackingParams>, context?: SegmentContext | null): void => {
  if (!window.analytics) return;
  track('Page View');
  window.analytics?.page();
  eventBasedOnPath(path, opts, context);
};

const reactivateAccount = (context: SegmentContext | null): void => {
  track('Subscription Reactivate', {}, context);
};

const planNameToTrack = (plan: string): string => {
  return plan.replace(/\s+/g, '-').toLowerCase();
};

const urlParamsToContext = (urlParams: ParsedQuery<string>): SegmentContext | null => {
  const { deviceType, advertiserId, appsFlyerId } = urlParams;

  if (deviceType && appsFlyerId) {
    const integrationAndContextObject: SegmentContext = {
      context: {
        device: {
          type: deviceType
        }
      },
      integrations: {
        AppsFlyer: {
          appsFlyerId
        }
      }
    };
    if (advertiserId && integrationAndContextObject.context?.device) {
      integrationAndContextObject.context.device.advertisingId = advertiserId;
    }
    return integrationAndContextObject;
  }
  return null;
};

export const useAppsFlyerObj = (search: string): SegmentContext | null => {
  const urlParams = parse(search);
  return urlParamsToContext(urlParams);
};

const trackILVPopup = (type: string, context?: SegmentContext | null): void => {
  const trackingParams = {
    type,
    created_at: new Date().toISOString()
  };
  track('ILV Modal Viewed', trackingParams, context);
};

interface SubscriptionV2Params extends TrackingParams {
  isStrongStart: boolean;
  planName: string;
  isSuccess: boolean;
}

const enableHeapContext = {
  integrations: {
    All: false,
    Heap: true,
    'Segment.io': true,
    Warehouses: {
      all: true
    }
  }
};

const subscriptionCancellation = (params: SubscriptionV2Params) => {
  track(
    params.isSuccess ? SUBSCRIPTION_CANCELLATION_SUCCEED : SUBSCRIPTION_CANCELLATION_FAILED,
    params,
    enableHeapContext
  );
};

const contactSupportToChangePlan = (params: Omit<SubscriptionV2Params, 'isSuccess'>) => {
  track(CONTACT_SUPPORT_CHANGE_PLAN, params, enableHeapContext);
};

const contactSupportForInsurance = () => {
  track(CONTACT_SUPPORT_FOR_INSURANCE, {}, enableHeapContext);
};

const subscriptionReactivation = (params: SubscriptionV2Params) => {
  track(
    params.isSuccess ? SUBSCRIPTION_REACTIVATION_SUCCEED : SUBSCRIPTION_REACTIVATION_FAILED,
    params,
    enableHeapContext
  );
};

const subscriptionReactivationCheckout = (params: SubscriptionV2Params) => {
  track(
    params.isSuccess ? SUBSCRIPTION_REACTIVATION_CHECKOUT_SUCCEED : SUBSCRIPTION_REACTIVATION_CHECKOUT_FAILED,
    params,
    enableHeapContext
  );
};

export default {
  telehealthConsent,
  identify,
  track,
  page,
  trackUpsell,
  reactivateAccount,
  convertCampaignId,
  planNameToTrack,
  urlParamsToContext,
  changePlanFlow,
  trackILVPopup,
  subscriptionCancellation,
  contactSupportToChangePlan,
  subscriptionReactivation,
  contactSupportForInsurance,
  subscriptionReactivationCheckout
};
