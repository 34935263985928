import React, { useState, useEffect, ReactNode } from 'react';
import { Flex, Heading, ListSelectorValue } from '@cerebral-inc/design-system';
import segment from 'utils/segment';
import { DimensionValue } from '@cerebral-inc/design-system/types/types/commonProps';
import { Question } from 'types/question';
import { getSegmentEvent, getSubmitSegmentEvent } from '../../helpers';
import { iterableIntegrations, segmentIntegrations } from '../../utils';
import { RebrandOnboardingButton } from '../OnboardingButton/RebrandOnboardingButton';
import { YesNoSelector } from './YesNoSelector';
import { MutiSelectorContent } from './MutiSelectorContent';

export interface IMultiSelectorSingleStackProps {
  question: Question;
  submitAnswer: (answer: string, question: Question, skip?: number) => void;
  isSingleSelect?: boolean;
  popUpText?: string;
  popUpComponent?({ isOpen, onClose }: { isOpen: boolean; onClose(): void }): ReactNode;
}

export const MultiSelectorSingleStack = ({
  question,
  submitAnswer,
  isSingleSelect,
  popUpText,
  popUpComponent
}: IMultiSelectorSingleStackProps) => {
  const [selections, setSelections] = useState<ListSelectorValue[]>([]);
  const { name, options, title } = question;

  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    const segmentName = getSegmentEvent(name);
    if (segmentName) segment.track(segmentName, undefined, segmentIntegrations);
  }, []);

  const handleSelectionClick = (clickedSelection: ListSelectorValue[]) => {
    if (clickedSelection.length === 0) {
      setSelections([]);
    }

    if (clickedSelection.some(item => item.value === 'None')) {
      setSelections(clickedSelection.filter(item => item.value === 'None'));
      return;
    }

    setSelections(clickedSelection.filter(item => item.value !== 'None'));
  };

  const handleSubmit = (selectedSelection?: string) => {
    // selectedSelection -> rebrand yes no question
    const isSelectionValid = selectedSelection && options.find(item => item.name === selectedSelection);
    const finalSelections = isSelectionValid
      ? selectedSelection
      : selections.map(selection => selection.value).join('|');

    submitAnswer(finalSelections, question);
    const eventName = getSubmitSegmentEvent(name);
    if (eventName) segment.track(eventName, { answered: finalSelections }, iterableIntegrations);
  };

  const isYesNoQuestion =
    options.length === 2 && ['Yes', 'No'].every(optionTitle => options.find(option => option.title === optionTitle));

  return (
    <Flex width="100%" background="warmGray100" alignItems="center" minHeight={'calc(100vh - 67px)' as DimensionValue}>
      <Flex
        width={{ base: '100%', sm: 480 }}
        paddingTop={40}
        paddingHorizontal={{ base: 24, sm: 0 }}
        background="warmGray100"
        minHeight={{ base: 'calc(100vh - 156px)' as DimensionValue, md: '100%' }}
      >
        <Heading size="h3" textAlign="center" color="warmGray900">
          {title as string}
        </Heading>

        {isYesNoQuestion ? (
          <YesNoSelector question={question} onSubmit={handleSubmit} />
        ) : (
          <MutiSelectorContent
            question={question}
            isSingleSelect={isSingleSelect}
            popUpText={popUpText}
            openPopup={openPopup}
            popUpComponent={popUpComponent}
            onOpenPopup={setOpenPopup}
            onSelect={handleSelectionClick}
            selections={selections}
          />
        )}
      </Flex>

      {!isYesNoQuestion && (
        <RebrandOnboardingButton
          text="Continue"
          onSubmit={handleSubmit}
          isStickyButton
          marginTop={40}
          isDisabled={selections.length === 0}
        />
      )}
    </Flex>
  );
};
