import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@cerebral-inc/ui';
import 'react-smartbanner/dist/main.css';
import { PersistGate } from 'redux-persist/integration/react';
import GlobalErrorCatcher from 'components/GlobalErrorCatcher';
import { QuestionsContextProvider } from 'contexts/QuestionsContext';
import QueryClientProvider from 'contexts/QueryClientProvider';
import { initDatadog } from 'utils/datadog-browser';
import { AuthProvider } from 'contexts/AuthProvider';
import { MaintenanceBoundary } from 'components/MaintenanceBoundary';
import KetchContextProvider from 'contexts/KetchContext';
import { CerebralDSProvider } from '@cerebral-inc/design-system';
import App from './App';
import configure_store from './store/configure_store';
import { VariationProvider } from './components/Variations/VariationContext';
import VersionChecker from './components/VersionChecker';
import { ApiProvider } from './utils/api';
import { AcceptScriptProvider } from './utils/accept-script';
import { GlobalsProvider } from './contexts/globals';
import ReferContextProvider from './contexts/ReferContextProvider';
import { SmartAppBanner } from './SmartAppBanner';
import { ConfigProvider } from './contexts/ConfigContext';

const { store, persistor } = configure_store();

(async () => {
  if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_MOCK_API === '1') {
    const { beginWorker } = await import('./__mocks__/msw');
    beginWorker();
  }
})();

initDatadog();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ThemeProvider>
    <QueryClientProvider>
      <ConfigProvider>
        <MaintenanceBoundary>
          <GlobalErrorCatcher>
            <VersionChecker>
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  <AcceptScriptProvider>
                    <ApiProvider>
                      <GlobalsProvider>
                        <Router basename={process.env.PUBLIC_URL}>
                          <SmartAppBanner />
                          <VariationProvider>
                            <QuestionsContextProvider>
                              <ReferContextProvider>
                                <AuthProvider>
                                  <KetchContextProvider>
                                    <CerebralDSProvider platform="web-client">
                                      <App />
                                    </CerebralDSProvider>
                                  </KetchContextProvider>
                                </AuthProvider>
                              </ReferContextProvider>
                            </QuestionsContextProvider>
                          </VariationProvider>
                        </Router>
                      </GlobalsProvider>
                    </ApiProvider>
                  </AcceptScriptProvider>
                </PersistGate>
              </Provider>
            </VersionChecker>
          </GlobalErrorCatcher>
        </MaintenanceBoundary>
      </ConfigProvider>
    </QueryClientProvider>
  </ThemeProvider>
);
